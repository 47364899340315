import CompRebateLayoutThree from '@/components/rebate/comp-rebate-layout-three/index.vue';
import RebateTable from '@/components/rebate/rebate-table/index.vue';
import rebateMixin from '@/mixins/nov-new-rebate';
import { getPurchAward } from '@/service/rebate/novNewRebate';
import Rmb from '@/components/common/rmb/index.vue';

export default {
    name: 'nov-new-rebate-purch-award',
    mixins: [rebateMixin],
    components: {
        CompRebateLayoutThree,
        RebateTable,
        Rmb,
    },
    data() {
        return {};
    },
    computed: {
        //  旗下创始人团队奖励
        founderColumns() {
            const columns = [
                {
                    label: '名称',
                    key: 'name',
                    render(name) {
                        return `${name}团队`;
                    },
                },
                {
                    label: '收益',
                    isRmb: true,
                    key: 'teamAward',
                },
            ];
            return columns;
        },

        //  旗下合伙人团队奖励
        partnerColumns() {
            const columns = [
                {
                    label: '名称',
                    key: 'name',
                    render(name) {
                        return `${name}`;
                    },
                },
                {
                    label: '收益',
                    isRmb: true,
                    key: 'teamAward',
                },
            ];
            return columns;
        },

        //  合伙人列表
        partnerList() {
            if (this.rebateInfo) {
                return this.rebateInfo.strategyList || [];
            }
            return [];
        },

        //  旗下创始人团队奖励
        founderList() {
            if (this.rebateInfo) {
                if (this.rebateInfo) {
                    return this.rebateInfo.uniteList || [];
                }
                return [];
            }
        },

        //  合伙人小计
        partnerBonusSum() {
            return this.partnerList.reduce((sum, item) => {
                return Number(sum) + Number(item.teamAward);
            }, 0);
        },

        founderBonusSum() {
            return this.founderList.reduce((sum, item) => {
                return Number(sum) + Number(item.teamAward);
            }, 0);
        },
    },
    methods: {
        //  初始化页面
        init() {
            const {
                startTime,
                endTime,
                agentId,
                userInfo: { levelId },
                curActivityId,
                curSystemId,
            } = this;
            getPurchAward({
                startTime,
                endTime,
                userId: agentId,
                systemId: curSystemId,
                levelId,
                actId: curActivityId,
            }).then(data => {
                this.rebateInfo = data;
            });
        },

        showDetail(row) {
            this.$router.push({
                name: 'nov-new-rebate-purch-award-detail',
                query: { ...this.$route.query, agentId: row.userId },
            });
        },

        changeSelectedActivity(value) {
            this.curActivity = value;
        },

        changeSelectedSystem(value) {
            this.curSystem = value;
        },
    },
};
